import MyIDBlock from './MyIDBlock';
// import './theme.less';
import iconSVG from '@plone/volto/icons/user.svg';

const applyConfig = (config) => {
  config.blocks.blocksConfig.myIDBlock = {
    id: 'myIDBlock',
    title: 'YC - My ID',
    icon: iconSVG,
    group: 'common',
    edit: MyIDBlock,
    view: MyIDBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
  };

  return config;
};

export default applyConfig;
