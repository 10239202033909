// src/actions/it_projects/it_projects.js
import { GET_IT_PROJECTS } from '../../constants/ActionTypes';
import config from '@plone/volto/registry';

export function getITProjects() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${config.settings.apiPath}/++api++/@it_projects`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      dispatch({
        type: GET_IT_PROJECTS,
        result: data.items, // Assuming the API returns an object with an 'items' property
      });
      return data; // Return the data to the caller if needed
    } catch (error) {
      console.error('Failed to fetch IT Projects:', error);
      dispatch({
        type: GET_IT_PROJECTS,
        error: error.message,
      });
      throw error; // Re-throw the error for the caller to handle
    }
  };
}
