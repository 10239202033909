import React from 'react';
import loadable from '@loadable/component';
import { Loader } from 'semantic-ui-react';
import myIdReducer from 'volto-yc-myapi/reducers/my_id/my_id';
import case_reducer from 'volto-yc-myapi/reducers/case_reducer/case_reducer';
import smart_classroom_reducer from 'volto-yc-myapi/reducers/smart_classroom_reducer/smart_classroom_reducer';
import applyYCITProjectsConfig from './blocks/YCITProjects/index';
import itProjectsReducer from './reducers/it_projects/it_projects'; // Import the IT projects reducer
import applyMyCaseBlockConfig from './blocks/MyCaseBlock/index';
import applyMyIDBlockConfig from './blocks/MyIDBlock/index';

// Dynamically load the views using @loadable/component
const MyID = loadable(() => import('volto-yc-myapi/views/MyID/MyID'), {
  fallback: <Loader active inline='centered' />,
});
const MyCase = loadable(() => import('volto-yc-myapi/views/MyCase/MyCase'), {
  fallback: <Loader active inline='centered' />,
});
const SmartClassroom = loadable(() => import('volto-yc-myapi/views/SmartClassroom/SmartClassroom'), {
  fallback: <Loader active inline='centered' />,
});

const applyConfig = (config) => {
  // Add reducers
  config.addonReducers.myIdReducer = myIdReducer;
  config.addonReducers.case_reducer = case_reducer;
  config.addonReducers.smart_classroom_reducer = smart_classroom_reducer;
  config.addonReducers.itProjects = itProjectsReducer; // Add the IT projects reducer

  // Content Types Views
  // config.views.contentTypesViews.myid = MyID;
  // config.views.contentTypesViews.mycase = MyCase;
  config.views.contentTypesViews.smartclassroom = SmartClassroom;

  // Layout Views
  // config.views.layoutViews.myid = MyID;
  // config.views.layoutViews.mycase = MyCase;
  config.views.layoutViews.smartclassroom = SmartClassroom;

  // Apply the YC IT Projects block configuration
  config = applyYCITProjectsConfig(config);

  config = applyMyCaseBlockConfig(config);
  config = applyMyIDBlockConfig(config);

  return config;
};

export default applyConfig;
