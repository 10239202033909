export const links = [
  {
    name: 'About',
    link: '/about',
    styles: 'cd-nav-gallery',
    description: 'Mission, Vision, and Values',
    sublinks: [
      {
        name: 'Points of Pride',
        link: '/about/points-of-pride',
        image: '/image-repository/points-of-pride-rev1920.webp/@@images/image-2560-e38bd3732d39f75f38f77b9e67380172.webp',
      },
      {
        name: 'Locations and Directions',
        link: '/about/directions',
        image: '/image-repository/location-directions.svg/@@images/image',
      },
      {
        name: 'Buildings',
        link: '/about/buildings',
        image: '/institutional-effectiveness/accreditation/@@images/preview_image/preview',
      },
      {
        name: 'History',
        link: '/about/history',
        image: '/image-repository/history2.jpg/@@images/image/preview',
      },
      {
        name: 'Consumer Information for Students',
        link: '/about/consumer-information',
        image: '/about-york/points-of-pride/picture1.png/@@images/image/great',
      },
    ],
  },
  {
    name: 'Academics',
    link: '/academics',
    styles: 'cd-secondary-nav',
    description: 'Academics Resources',
    sublinks: [
      {
        name: 'Academics Affairs',
        link: '/academics/academic-affairs',
        styles: 'has-children',
        sublink: [
          { name: 'Academic Advisement Center', link: '/advisement' },
          { name: 'Academic Calendar', link: '/registrar/calendar', styles: 'menustrong' },
          { name: 'Accelerate, Complete, Engage (ACE)', link: '/ace' },
          { name: 'Bulletin - Graduate', link: 'https://york-graduate.catalog.cuny.edu/', styles: 'menustrong' },
          { name: 'Bulletin - Undergraduate', link: 'https://york-undergraduate.catalog.cuny.edu/', styles: 'menustrong' },
          { name: 'Course Schedule', link: '/registrar/course-schedule' },
          { name: 'Honors Program', link: '/honors' },
          { name: 'Office of Student Academic Services', link: '/osas' },
          { name: 'Registrar', link: '/registrar', styles: 'menustrong' },
          { name: 'Undergraduate Research', link: '/undergraduate-research' },
          { name: 'Writing Across the Curriculum', link: '/wac' },
        ],
      },
      {
        name: 'School of Arts & Sciences',
        link: '/arts-and-sciences',
        styles: 'has-children',
        sublink: [
          { name: 'Behavioral Sciences', link: '/behavioral-sciences' },
          { name: 'Biology', link: '/biology' },
          { name: 'Chemistry', link: '/chemistry' },
          { name: 'Earth and Physical Sciences', link: '/earth-and-physical-sciences' },
          { name: 'English', link: '/english' },
          { name: 'History, Philosophy, and Anthropology', link: '/history-and-philosophy' },
          { name: 'Mathematics and Computer Science', link: '/mathematics-and-computer-science' },
          { name: 'Performing and Fine Arts', link: '/performing-and-fine-arts' },
          { name: 'World Languages, Literatures, and Humanities', link: '/foreign-languages-esl-humanities' },
        ],
      },
      {
        name: 'School of Business & Information Systems',
        link: '/business-and-information-systems',
        styles: 'has-children',
        sublink: [
          { name: 'Accounting and Finance', link: '/accounting-and-finance' },
          { name: 'Business and Economics', link: '/business-economics' },
          { name: 'CUNY Aviation Institute', link: '/aviation-institute' },
        ],
      },
      {
        name: 'School of Health Sciences & Professional Programs',
        link: '/health-sciences-and-professional-programs',
        styles: 'has-children',
        sublink: [
          { name: 'Health & Human Performance', link: '/health-human-performance' },
          { name: 'Health Professions', link: '/health-professions' },
          { name: 'Nursing', link: '/nursing' },
          { name: 'Occupational Therapy', link: '/occupational-therapy' },
          { name: 'Social Work', link: '/social-work' },
          { name: 'Teacher Education', link: '/teacher-education' },
        ],
      },
    ],
  },
  {
    name: 'Admissions',
    link: '/admissions',
    styles: 'cd-nav-icons',
    description: 'Admissions Home',
    sublinks: [
      {
        name: 'Freshman Admissions',
        link: '/admissions/freshman',
        styles: 'item-1',
        description: 'I have never attended college',
      },
      {
        name: 'Transfer Admissions',
        link: '/admissions/transfer',
        styles: 'item-2',
        description: 'I studied at other post-secondary institutions',
      },
      {
        name: 'Graduate Admissions',
        link: '/admissions/graduate',
        styles: 'item-3',
        description: 'I graduated from a post-secondary institution',
      },
      {
        name: 'International Admissions',
        link: '/admissions/international',
        styles: 'item-4',
        description: 'I\'m not a US Resident / Citizen',
      },
      {
        name: 'Veterans Admission',
        link: '/veterans-affairs',
        styles: 'item-5',
        description: 'I served in the US military',
      },
      {
        name: 'Other Applicants',
        link: '/admissions/others',
        styles: 'item-6',
        description: 'Non-degree, Second Degree, Adult Learner, Senior Citizens',
      },
      {
        name: 'Admissions Team/Office Hours',
        link: '/admissions/team',
        styles: 'item-7',
        description: 'Meet the York College Admission Team, Virtual and In-person Walk-In Hours',
      },
      {
        name: 'Campus Tour',
        link: '/admissions/campus-tours',
        styles: 'item-8',
        description: 'Take a virtual Tour of our campus',
      },
      {
        name: 'Financial Aid',
        link: '/financial-aid',
        styles: 'item-9',
        description: 'I need help paying for college',
      },
    ],
  },
  {
    name: 'Student Life',
    link: '/current-student',
    styles: 'cd-secondary-nav',
    description: 'Student Life Resources',
    sublinks: [
      {
        name: 'Online Services for students',
        link: '/current-student#tools',
        styles: 'has-children',
        sublink: [
          { name: 'Bookstore', link: '/news/2018/the-york-college-online-bookstore' },
          { name: 'CUNY ePermit', link: 'https://www2.cuny.edu/about/administration/offices/registrar/resources/ePermit/' },
          { name: 'DegreeWorks', link: 'https://degreeworks.cuny.edu/Dashboard_yk' },
          { name: 'Dropbox', link: 'https://dropbox.cuny.edu/' },
          { name: 'IT Self Service', link: '/it/service-delivery-unit/y-connect' },
          { name: 'LinkedIn Learning', link: '/ctlet/teaching-and-learning-tools/linkedin-learning', styles: 'menustrong' },
          { name: 'MyID / Password Management', link: '/myid' },
          { name: 'Navigate', link: '/academics/navigate' },
          { name: 'Parking Permits and Violations', link: 'https://parking.york.cuny.edu/' },
          { name: 'Secure Documents Portal', link: 'https://documents.york.cuny.edu/' },
          { name: 'Tutor.com', link: '/tutor', styles: 'menustrong' },
          { name: 'VPN Access', link: '/it/network-access/vpn', styles: 'menustrong' },
          { name: 'Zoom', link: 'https://cuny.zoom.us' },
        ],
      },
      {
        name: 'Student Affairs and Enrollment Management',
        link: '/student-development',
        styles: 'has-children',
        sublink: [
          { name: 'Athletics', link: 'http://www.yorkathletics.com/' },
          { name: 'Career Services', link: '/career-services' },
          { name: 'Child and Family Center', link: '/child-and-family-center' },
          { name: 'Counseling Center', link: '/counseling-center' },
          { name: 'First-Year Experience (FYE)', link: '/student-development/fye' },
          { name: 'Male Initiative Program', link: '/mens-center' },
          { name: 'Mentoring', link: '/student-development/mentoring' },
          { name: 'Office of Enrollment Management', link: '/enrollment-mngmt-office', styles: 'has-children' },
          { name: 'Office of Ombudsperson', link: '/ombudsperson' },
          { name: 'Office of Student Activities', link: '/student-development/student-activities', styles: 'has-children' },
          { name: 'Percy E. Sutton SEEK program', link: '/seek' },
          { name: 'Testing Center', link: '/testing' },
          { name: 'Veteran\'s Affairs', link: '/veterans-affairs' },
          { name: 'Wellness and Resources', link: '/cares', styles: 'has-children' },
        ],
      },
      {
        name: 'Student Resources',
        link: '/current-student',
        styles: 'has-children',
        sublink: [
          { name: 'Behavioral Intervention Team (BIT)', link: '/student-development/bit' },
          { name: 'Calendar', link: '/events' },
          { name: 'Center for Teaching, Learning and Educational Technologies (CTLET)', link: '/ctlet' },
          { name: 'Consumer Information', link: '/about/consumer-information' },
          { name: 'Collaborative Learning Center (CLC)', link: '/collaborative-learning-center', styles: 'menustrong' },
          { name: 'Public Safety', link: '/public-safety' },
          { name: 'Scholarships Center', link: '/scholarship', styles: 'menustrong' },
          { name: 'Senate', link: '/senate', styles: 'menustrong' },
          { name: 'Student Clubs and Organizations', link: '/student-development/student-activities/student-clubs-and-organizations' },
          { name: 'Student Government Association (SGA)', link: '/student-development/student-activities/student-government' },
          { name: 'Study Abroad', link: '/study-abroad' },
          { name: 'Welcome Center', link: '/welcome-center', styles: 'menustrong' },
        ],
      },
      { name: 'Brightspace / Blackboard', link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'boxhalf' },
      { name: 'CUNYfirst', link: 'https://home.cunyfirst.cuny.edu/', styles: 'boxhalf' },
      { name: 'EMAIL / Office 365', link: 'https://www.outlook.com/owa/yorkmail.cuny.edu', styles: 'boxhalf' },
      { name: 'Schedule Builder', link: '/registrar/schedule-builder', styles: 'boxhalf' },
      { name: 'Academic Planning', link: '/advisement' },
      { name: 'Financial Aid', link: '/financial-aid', styles: 'menustrong' },
      { name: 'Registration', link: '/registrar', styles: 'menustrong' },
      { name: 'Tuition and Payment', link: '/bursar', styles: 'menustrong' },
      { name: 'Library', link: '/library' },
      { name: 'Commencement', link: '/commencement' },
    ],
  },
  {
    name: 'Faculty / Staff',
    link: '/faculty-staff',
    styles: 'cd-secondary-nav',
    description: 'Faculty and Staff Resources',
    sublinks: [
      {
        name: 'Online Services for employees',
        link: '/faculty-staff#tools',
        styles: 'has-children',
        sublink: [
          { name: 'B&G Work Order', link: '/administrative/office-of-facilities-and-planning/bg/archibus' },
          { name: 'Bookstore', link: '/news/2018/the-york-college-online-bookstore' },
          { name: 'DegreeWorks', link: 'https://degreeworks.cuny.edu/' },
          { name: 'Dropbox', link: 'https://dropbox.cuny.edu/' },
          { name: 'LinkedIn Learning', link: '/ctlet/teaching-and-learning-tools/linkedin-learning' },
          { name: 'Password Management', link: 'https://www.york.cuny.edu/myPassword/' },
          { name: 'Parking Permits and Violations', link: 'https://parking.york.cuny.edu' },
          { name: 'Qualtrics', link: '/it/service-delivery-unit/qualtrics' },
          { name: 'VPN Access', link: '/it/network-access/vpn', styles: 'menustrong' },
          { name: 'York Assessment Management System (YAMS)', link: '/yams' },
          { name: 'York Website/CMS', link: '/login?return_url=/dashboard' },
          { name: 'Zoom', link: 'https://cuny.zoom.us' },
        ],
      },
      {
        name: 'Administration',
        link: '/administrative',
        styles: 'has-children',
        sublink: [
          { name: 'Assessment', link: '/institutional-effectiveness/assessment', styles: 'menustrong' },
          { name: 'Environment Health and Safety', link: '/administrative/office-of-facilities-and-planning/environmental' },
          { name: 'Facilities and Planning', link: '/administrative/office-of-facilities-and-planning' },
          { name: 'Financial Planning and Budget', link: '/administrative/business-office' },
          { name: 'Government Relations and Strategic Initiatives', link: '/president/government-and-community-relations' },
          { name: 'Human Resources', link: '/human-resources', styles: 'menustrong' },
          { name: 'Information Technology', link: '/it' },
          { name: 'Office of Institutional Advancement', link: '/ia' },
          { name: 'Office of Institutional Effectiveness', link: '/institutional-effectiveness' },
          { name: 'Office of the President', link: '/president', styles: 'menustrong' },
          { name: 'Office of Research and Sponsored Programs', link: '/orsp' },
          { name: 'Public Safety', link: '/public-safety', styles: 'menustrong' },
        ],
      },
      {
        name: 'Employee Resources',
        link: '/faculty-staff',
        styles: 'has-children',
        sublink: [
          { name: 'Behavioral Intervention Team (BIT)', link: '/student-development/bit' },
          { name: 'Calendar', link: '/events' },
          { name: 'Center for Teaching, Learning and Educational Technologies (CTLET)', link: '/ctlet' },
          { name: 'Cybersecurity Awareness Training', link: 'https://bbhosted.cuny.edu/webapps/blackboard/execute/announcement?method=search&context=course_entry&course_id=_2323641_1&handle=announcements_entry&mode=view', styles: 'menustrong' },
          { name: 'Library', link: '/library', styles: 'menustrong' },
          { name: 'Marketing and Communications', link: '/marketing' },
          { name: 'Office of the Bursar', link: '/bursar' },
          { name: 'Printing Services', link: '/administrative/printing-services' },
          { name: 'Senate', link: '/senate', styles: 'menustrong' },
          { name: 'Wellness and Resources', link: '/cares' },
        ],
      },
      { name: 'AEMS | HR/PR Assist', link: '/human-resources/current-employee', styles: 'boxhalf' },
      { name: 'Brightspace / Blackboard', link: 'https://www.cuny.edu/about/administration/offices/cis/technology-services/brightspace/', styles: 'boxhalf' },
      { name: 'CUNYfirst', link: 'https://home.cunyfirst.cuny.edu/', styles: 'boxhalf' },
      { name: 'EMAIL / Outlook', link: 'https://outlook.office.com/mail/', styles: 'boxhalf' },
      { name: 'Office 365', link: 'https://login.microsoftonline.com/?whr=login.cuny.edu', styles: 'boxhalf' },
      { name: 'CUNYBuy', link: 'https://solutions.sciquest.com/apps/Router/SAMLAuth/CUNY', styles: 'menustrong' },
      { name: 'IT Self Service', link: '/it/service-delivery-unit/y-connect' },
      { name: 'Navigate', link: '/academics/navigate' },
    ],
  },
  {
    name: 'Alumni / Friends',
    link: '/alumni',
    styles: 'cd-nav-gallery',
    description: 'Alumni, Friends, and Supporters',
    sublinks: [
      {
        name: 'Institutional Advancement',
        link: '/ia',
        image: '/image-repository/history.jpg/@@images/image/preview',
      },
      {
        name: 'YC Foundation, Inc.',
        link: '/ia/foundation-board',
        image: '/image-repository/friends-ycf.svg/@@images/image/preview',
      },
      {
        name: 'Alumni Association, Inc.',
        link: '/alumni/association',
        image: '/image-repository/friends-alumni-1.jpg/@@images/image/preview',
      },
      {
        name: 'Alumni Relations',
        link: '/ia/devalum',
        image: '/image-repository/alumni-resources.jpg/@@images/image/preview',
      },
      {
        name: 'Rent a Space',
        link: '/rent-a-space',
        image: '/image-repository/rental.webp/@@images/image/preview',
      },
    ],
  },
];
