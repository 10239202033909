import MyCaseBlock from './MyCaseBlock';
// import './theme.less';
import iconSVG from '@plone/volto/icons/table.svg';

const applyConfig = (config) => {
  config.blocks.blocksConfig.myCaseBlock = {
    id: 'myCaseBlock',
    title: 'YC - My Cases',
    icon: iconSVG,
    group: 'common',
    edit: MyCaseBlock,
    view: MyCaseBlock,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
  };

  return config;
};

export default applyConfig;
