import { MY_ID } from 'volto-yc-myapi/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export default function my_id(id, five) {
  return {
    type: MY_ID,
    request: {
      op: 'get',
      path: flattenToAppURL(`/@my_id?id=${id}&five=${five}`),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { id, five },
    },
  };
}
