// src/reducers/it_projects/it_projects.js
import { GET_IT_PROJECTS } from '../../constants/ActionTypes';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const itProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IT_PROJECTS:
      return {
        ...state,
        items: action.result || [], // Store the IT projects data
        loading: false,
        error: action.error || null, // Store any error messages
      };
    default:
      return state;
  }
};

export default itProjectsReducer;
