export const MY_ID = 'MY_ID';

export const MY_CASE = 'MY_CASE';

export const SMART_CLASSROOM = 'SMART_CLASSROOM';

export const ALL_SMART_CLASSROOMS = 'ALL_SMART_CLASSROOMS';

// src/constants/ActionTypes.js
export const GET_IT_PROJECTS = 'GET_IT_PROJECTS';
