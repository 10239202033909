import YCITProjectsEdit from './YCITProjectsEdit';
import YCITProjectsView from './YCITProjectsView';
// import './theme.less';
import iconSVG from '@plone/volto/icons/table.svg';

const applyConfig = (config) => {
  config.blocks.blocksConfig.ycITProjects = {
    id: 'ycITProjects',
    title: 'YC - IT Projects',
    icon: iconSVG,
    group: 'common', // Or a more relevant group
    edit: YCITProjectsEdit,
    view: YCITProjectsView,
    restricted: false,
    mostUsed: false,
    blockHasOwnFocusManagement: false,
    sidebarTab: 1,
  };

  return config;
};

export default applyConfig;
