import { votes } from './reducers';
import React from 'react';
import loadable from '@loadable/component';
import { Loader } from 'semantic-ui-react';
import SummaryView from '@plone/volto/components/theme/View/SummaryView';

// Dynamically load views and widgets using @loadable/component
const TechFeeView = loadable(
  () => import('volto-yc-voting/components/Views/TechFeeView'),
  {
    fallback: <Loader active inline="centered" />,
  },
);
const applyConfig = (config) => {
  config.addonReducers.votes = votes;
  config.views.contentTypesViews.tech_fee_committee_rfp = TechFeeView;
  config.views.contentTypesViews['Tech Fee Container'] = SummaryView;
  return config;
};

export default applyConfig;
