import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { getITProjects } from '../../actions/it_projects/it_projects';
import { Table } from 'semantic-ui-react'; // Import Table from Semantic UI React

const messages = defineMessages({
  loading: {
    id: 'ycitprojects.loading',
    defaultMessage: 'Loading IT Projects...',
  },
  noProjects: {
    id: 'ycitprojects.noprojects',
    defaultMessage: 'No IT Projects found.',
  },
  error: {
    id: 'ycitprojects.error',
    defaultMessage: 'Error loading IT Projects.',
  },
  projectName: {
    id: 'ycitprojects.projectName',
    defaultMessage: 'Name',
  },
  projectId: {
    id: 'ycitprojects.projectId',
    defaultMessage: 'ID',
  },
  initiatingDepartment: {
    id: 'ycitprojects.initiatingDepartment',
    defaultMessage: 'Department',
  },
  projectState: {
    id: 'ycitprojects.projectState',
    defaultMessage: 'State',
  },
  projectEstStart: {
    id: 'ycitprojects.projectEstStart',
    defaultMessage: 'Est. Start',
  },
  projectEstEnd: {
    id: 'ycitprojects.projectEstEnd',
    defaultMessage: 'Est. End',
  },
  summary: {
    id: 'ycitprojects.summary',
    defaultMessage: 'Summary',
  },
});

const YCITProjectsView = ({ itProjects, getITProjects, loading, error }) => {
  const intl = useIntl();
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [data, setData] = useState(itProjects); // Add a local state for data

  useEffect(() => {
    getITProjects();
  }, [getITProjects]);

  useEffect(() => {
    setData(itProjects); // Update local state when itProjects changes
  }, [itProjects]);

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setData([...itProjects].sort((a, b) => (a[clickedColumn] > b[clickedColumn] ? 1 : -1)));
      setDirection('ascending');
      return;
    }

    setData([...data].reverse());

    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  if (loading) {
    return <div>{intl.formatMessage(messages.loading)}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!data || data.length === 0) {
    return <div>{intl.formatMessage(messages.noProjects)}</div>;
  }

  return (
    <div className='yc-it-projects-view'>
      <h2>IT Projects</h2>
      <Table striped sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'Project_Name' ? direction : null}
              onClick={handleSort('Project_Name')}
            >
              {intl.formatMessage(messages.projectName)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Project_ID' ? direction : null}
              onClick={handleSort('Project_ID')}
            >
              {intl.formatMessage(messages.projectId)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Initiating_Department' ? direction : null}
              onClick={handleSort('Initiating_Department')}
            >
              {intl.formatMessage(messages.initiatingDepartment)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Project_State' ? direction : null}
              onClick={handleSort('Project_State')}
            >
              {intl.formatMessage(messages.projectState)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Project_Est_Start' ? direction : null}
              onClick={handleSort('Project_Est_Start')}
            >
              {intl.formatMessage(messages.projectEstStart)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Project_Est_End' ? direction : null}
              onClick={handleSort('Project_Est_End')}
            >
              {intl.formatMessage(messages.projectEstEnd)}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'Summary' ? direction : null}
              onClick={handleSort('Summary')}
            >
              {intl.formatMessage(messages.summary)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((project) => (
            <Table.Row key={project.Project_ID}>
              <Table.Cell>{project.Project_Name}</Table.Cell>
              <Table.Cell>{project.Project_ID}</Table.Cell>
              <Table.Cell>{project.Initiating_Department}</Table.Cell>
              <Table.Cell>{project.Project_State}</Table.Cell>
              <Table.Cell>{project.Project_Est_Start}</Table.Cell>
              <Table.Cell>{project.Project_Est_End}</Table.Cell>
              <Table.Cell>{project.Summary}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  itProjects: state.itProjects.items,
  loading: state.itProjects.loading,
  error: state.itProjects.error,
});

const mapDispatchToProps = {
  getITProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(YCITProjectsView);
