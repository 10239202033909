import React, { useState } from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import social01 from './ico-social01.svg';
import social02 from './ico-social02.svg';
import social03 from './ico-social03.svg';
import social04 from './ico-social04.svg';
import social07 from './ico-social07.svg';
import social08 from './ico-social08.svg';
import cuny from './cuny-white2018.png';
import { useGoogleAnalytics } from 'volto-google-analytics';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

const Footer = ({ intl }) => {
  useGoogleAnalytics();
  const { settings } = config;
  const lang = useSelector((state) => state.intl.locale);
  const [showChatbot, setShowChatbot] = useState(false);

  return (
    <Segment
      role='contentinfo'
      vertical
      padded
      inverted
      color='grey'
      textAlign='left'
      id='footer'
    >
      <Container>
        <div className='discreet'>
          <Grid className='ui stackable four column grid'>
            <Grid.Column>
              <p>
                <UniversalLink href='/about/consumer-information'>Consumer Information</UniversalLink>
                <br />
                <UniversalLink href='/diversity-and-compliance'>Diversity and Compliance</UniversalLink>
                <br />
                <UniversalLink href='/human-resources/jobs'>Employment</UniversalLink>
                <br />
                <UniversalLink href='/public-safety'>Public Safety</UniversalLink>
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <UniversalLink href='/directory'>Directory</UniversalLink>
                <br />
                <UniversalLink href='/accessibility-info' accessKey='0'>Accessibility</UniversalLink>
                <br />
                <UniversalLink href='/webteam'>Website</UniversalLink>
                <br />
                <UniversalLink href='/it/webteam/copyright-infringement'>
                  <FormattedMessage
                    id='{copyright} {current_year}'
                    defaultMessage='{copyright} {current_year}'
                    values={{
                      copyright: (
                        <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
                      ),
                      current_year: new Date().getFullYear(),
                      plonefoundation: (
                        <UniversalLink href='/webteam/website-privacy-policy'>
                          <FormattedMessage id='Plone Foundation' defaultMessage='Plone Foundation' />
                        </UniversalLink>
                      ),
                    }}
                  />
                </UniversalLink>
              </p>
            </Grid.Column>
            <Grid.Column width={6} textAlign='right'>
              <p>
                York College / CUNY
                <br /> 94 - 20 Guy R. Brewer Blvd.
                <br /> Jamaica, NY 11451
                <br /> <abbr title='Phone'>P: </abbr>718-262-2000
              </p>
              <div id='yc-social'>
                <a href='https://www.youtube.com/user/YorkTelevision' rel='noopener' target='_blank'>
                  <img src={social04} alt='youtube' width='30' height='30' />
                </a>
                &nbsp;
                <a href='https://www.instagram.com/york_college_cuny/' rel='noopener' target='_blank'>
                  <img src={social08} alt='Instagram' width='30' height='30' />
                </a>
                &nbsp;
                <a href='https://www.linkedin.com/school/269983' rel='noopener' target='_blank'>
                  <img src={social02} alt='LinkedIn' width='30' height='30' />
                </a>
                &nbsp;
                <a href='https://www.facebook.com/pages/York-College-CUNY/175813186163' target='_blank'>
                  <img src={social01} alt='Facebook' width='30' height='30' />
                </a>
                &nbsp;
                <a href='https://twitter.com/YorkCollegeCUNY' rel='noopener' target='_blank'>
                  <img src={social03} alt='twitter' width='30' height='30' />
                </a>
                &nbsp;
                <a href='/about/directions'>
                  <img src={social07} alt='Directions' width='30' height='30' />
                </a>
                &nbsp;
                <a href='https://cuny.edu/' id='cuny' rel='noopener' target='_blank'>
                  <img src={cuny} alt='CUNY' width='69' height='30' decoding='async' loading='lazy' />
                </a>
              </div>
            </Grid.Column>
          </Grid>
        </div>
        {/*<Chatbot showChatbot={showChatbot} setShowChatbot={setShowChatbot} />*/}
      </Container>
    </Segment>
  );
};

export default injectIntl(Footer);
