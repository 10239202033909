import React, { useState } from 'react';
import { connect } from 'react-redux';
import my_id from 'volto-yc-myapi/actions/my_id/my_id';
import { Button, Form, FormGroup, FormInput, Message, Modal, Segment, Table } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'myid.title',
    defaultMessage: 'My ID',
  },
  description: {
    id: 'myid.description',
    defaultMessage: 'Students, do you know what your York College Network Account and Yorkmail is? As a York College student you have 3 different accounts to connect to the diferent systems.',
  },
  cunyFirstLogin: {
    id: 'myid.cunyFirstLogin',
    defaultMessage: 'CUNYfirst Login',
  },
  cunyFirstDescription: {
    id: 'myid.cunyFirstDescription',
    defaultMessage: 'CUNYfirst, Brightspace, Navigate, Zoom, DegreeWorks, CUNYbuy, FACTS etc.',
  },
  yorkCollegeNetwork: {
    id: 'myid.yorkCollegeNetwork',
    defaultMessage: 'York College Network',
  },
  yorkCollegeNetworkDescription: {
    id: 'myid.yorkCollegeNetworkDescription',
    defaultMessage: 'Computer login, print, YC Cardinal App, YC Digital ID, WiFi, parking permit, Secure Document portal, VDI, VPN, etc.',
  },
  yorkmailOffice365: {
    id: 'myid.yorkmailOffice365',
    defaultMessage: 'Yorkmail / Office 365',
  },
  yorkmailOffice365Description: {
    id: 'myid.yorkmailOffice365Description',
    defaultMessage: 'Access York College Email and Microsoft Office 365.',
  },
  findAndActivate: {
    id: 'myid.findAndActivate',
    defaultMessage: 'Find and Activate',
  },
  cunyIDLabel: {
    id: 'myid.cunyIDLabel',
    defaultMessage: 'CUNY ID',
  },
  last5SSNLabel: {
    id: 'myid.last5SSNLabel',
    defaultMessage: 'Last 5 of SSN',
  },
  showMe: {
    id: 'myid.showMe',
    defaultMessage: 'Show Me',
  },
  studentIDResults: {
    id: 'myid.studentIDResults',
    defaultMessage: 'Student ID Results',
  },
  account: {
    id: 'myid.account',
    defaultMessage: 'Account',
  },
  username: {
    id: 'myid.username',
    defaultMessage: 'Username',
  },
  activationLink: {
    id: 'myid.activationLink',
    defaultMessage: 'Activation Link',
  },
  cunyfirstLoginAccount: {
    id: 'myid.cunyfirstLoginAccount',
    defaultMessage: 'CUNY Login',
  },
  cunyfirstLoginUsername: {
    id: 'myid.cunyfirstLoginUsername',
    defaultMessage: 'FirstName.LastName##@login.cuny.edu',
  },
  yorkCollegeNetworkAccount: {
    id: 'myid.yorkCollegeNetworkAccount',
    defaultMessage: 'York Login',
  },
  emailOffice365Account: {
    id: 'myid.emailOffice365Account',
    defaultMessage: 'e-mail / Office 365',
  },
  incorrectUsernameSSN: {
    id: 'myid.incorrectUsernameSSN',
    defaultMessage: 'The Username or SSN is incorrect, Please verify your information and try again.',
  },
  passwordReset: {
    id: 'myid.passwordReset',
    defaultMessage: 'Password Reset',
  },
  forgotPasswordMessage: {
    id: 'myid.forgotPasswordMessage',
    defaultMessage: 'If you already know and activated your York Login but just forgot your password you can reset it online using the My Password self-service page or contact the IT Virtual Office.',
  },
  studentEmailLinkMessage: {
    id: 'myid.studentEmailLinkMessage',
    defaultMessage: 'To reset your activated Yorkmail/Office 365, please visit the Student Email webpage.',
  },
});

const MyIDBlock = ({ my_id, sam_account_name: initialSamAccountName, mail: initialMail }) => {
  const [id, setId] = useState('');
  const [five, setFive] = useState('');
  const [open, setOpen] = useState(false);
  const [sam_account_name, setSamAccountName] = useState(initialSamAccountName || '');
  const [mail, setMail] = useState(initialMail || '');
  const [errorMessage, setErrorMessage] = useState(null);
  const intl = useIntl();

  const getMyID = async () => {
    try {
      const response = await my_id(id, five);
      if (response?.items?.length > 0) {
        const result = response.items[0];
        setSamAccountName(result?.sam_account_name);
        setMail(result?.mail);
        setErrorMessage(null);
        setOpen(true);
      } else {
        setSamAccountName('');
        setMail('');
        setErrorMessage(intl.formatMessage(messages.incorrectUsernameSSN));
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage(intl.formatMessage(messages.incorrectUsernameSSN));
      setOpen(true);
      setSamAccountName('');
      setMail('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'id') {
      setId(value);
    } else if (name === 'five') {
      setFive(value);
    }
  };

  const onOpen = () => setOpen(true);
  const onClose = () => {
    setOpen(false);
    setErrorMessage(null);
  };

  return (
    <>

      <h2>{intl.formatMessage(messages.findAndActivate)}</h2>
      <Segment inverted>
        <Form inverted method='post'>
          <FormGroup widths='equal'>
            <FormInput
              fluid
              label={intl.formatMessage(messages.cunyIDLabel)}
              type='text'
              name='id'
              value={id}
              onChange={handleChange}
              placeholder='CUNYfirst 8 or 9 digits ID'
              pattern='[0-9]{8}'
              autoComplete='off'
              required
            />
            <FormInput
              fluid
              label={intl.formatMessage(messages.last5SSNLabel)}
              name='five'
              value={five}
              onChange={handleChange}
              placeholder='or External System ID for international students'
              type='password'
              minLength='5'
              maxLength='5'
              autoComplete='off'
              required
            />
          </FormGroup>

          <Modal
            trigger={<Button primary onClick={getMyID}>{intl.formatMessage(messages.showMe)}</Button>}
            className='view-wrapper'
            size='fullscreen'
            closeIcon
            open={open}
            onOpen={onOpen}
            onClose={onClose}
          >
            <Modal.Header>{intl.formatMessage(messages.studentIDResults)}</Modal.Header>
            <Modal.Content>
              {errorMessage ? (
                <Message error>
                  {errorMessage}
                </Message>
              ) : (
                <Table className='table table-striped'>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>{intl.formatMessage(messages.account)}</Table.HeaderCell>
                      <Table.HeaderCell>{intl.formatMessage(messages.username)}</Table.HeaderCell>
                      <Table.HeaderCell>{intl.formatMessage(messages.activationLink)}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{intl.formatMessage(messages.cunyfirstLoginAccount)}</Table.Cell>
                      <Table.Cell>{intl.formatMessage(messages.cunyfirstLoginUsername)}</Table.Cell>
                      <Table.Cell> </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{intl.formatMessage(messages.yorkCollegeNetworkAccount)}</Table.Cell>
                      <Table.Cell>{sam_account_name}</Table.Cell>
                      <Table.Cell>
                        <a href='https://password.york.cuny.edu/' target='ycn'>
                          {intl.formatMessage(messages.activationLink)}
                        </a>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{intl.formatMessage(messages.emailOffice365Account)}</Table.Cell>
                      <Table.Cell>{mail}</Table.Cell>
                      <Table.Cell>
                        <a href='http://outlook.com/yorkmail.cuny.edu' target='outlook'>
                          {intl.formatMessage(messages.activationLink)}
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
              {!errorMessage && (
                <p>
                  Default Password (Case Sensitive):
                  <strong>
                    Yc + MMYYYY of Your Date of Birth + LAST 4 of SSN (eg. Yc1219985400)
                  </strong>
                </p>
              )}
            </Modal.Content>
          </Modal>
        </Form>
      </Segment>
    </>
  );
};

const mapStateToProps = (state) => ({
  sam_account_name: state.myIdReducer.sam_account_name,
  mail: state.myIdReducer.mail,
});

const mapDispatchToProps = {
  my_id,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyIDBlock);
