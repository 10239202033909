import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { my_case } from 'volto-yc-myapi/actions/my_case/my_case';
import { Button, Form, Input, Table } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'mycase.title',
    defaultMessage: 'Y-Connect Portal',
  },
  description: {
    id: 'mycase.description',
    defaultMessage: 'Welcome to York College Y-Connect Self Service Portal',
  },
  openYConnect: {
    id: 'mycase.openYConnect',
    defaultMessage: 'Open Y-Connect',
  },
  quickTicket: {
    id: 'mycase.quickTicket',
    defaultMessage: 'Use Quick Ticket Form',
  },
  myCases: {
    id: 'mycase.myCases',
    defaultMessage: 'My Cases',
  },
  casesDescription: {
    id: 'mycase.casesDescription',
    defaultMessage: 'By using your CUNY ID faculty and staff can see tickets they currently have open',
  },
  search: {
    id: 'mycase.search',
    defaultMessage: 'Search',
  },
  cunyID: {
    id: 'mycase.cunyID',
    defaultMessage: 'CUNY ID',
  },
  caseID: {
    id: 'mycase.caseID',
    defaultMessage: 'CASE ID',
  },
  descriptionLabel: {
    id: 'mycase.descriptionLabel',
    defaultMessage: 'DESCRIPTION',
  },
  created: {
    id: 'mycase.created',
    defaultMessage: 'CREATED',
  },
  statusName: {
    id: 'mycase.statusName',
    defaultMessage: 'Status Name',
  },
  whatIsYConnect: {
    id: 'mycase.whatIsYConnect',
    defaultMessage: 'What is Y-Connect',
  },
  whyYouNeedIt: {
    id: 'mycase.whyYouNeedIt',
    defaultMessage: 'Why you need it?',
  },
  noCasesFound: {
    id: 'mycase.noCasesFound',
    defaultMessage: 'No cases found for this CUNY ID.',
  },
  loading: {
    id: 'mycase.loading',
    defaultMessage: 'Loading Cases...',
  },
  error: {
    id: 'mycase.error',
    defaultMessage: 'Error loading cases.',
  },
});

const MyCaseBlock = ({
                       my_case,
                       queue_name,
                       case_id,
                       user_empid,
                       created,
                       service,
                       last_comment,
                       status_name,
                       description,
                       department,
                       submitter_name,
                       first_name,
                       last_name,
                       work_phone,
                       email_address,
                     }) => {
  const [emplid, setEmplid] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const intl = useIntl();

  const getMyCase = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await my_case(emplid);
      if (response.error) {
        setError(response.error);
        setItems([]);
      } else {
        setItems(response.items);
      }
    } catch (e) {
      setError(intl.formatMessage(messages.error));
      setItems([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // You might want to fetch data here if needed when the component mounts
  }, []); // Only run once on mount

  const handleChange = (e) => {
    setEmplid(e.target.value);
  };

  return (
    <div className='mycase-block'>

      <div className='styled-columnsBlock gray-box has--style_name--grayBox styled'>
        <h2>{intl.formatMessage(messages.myCases)}</h2>
        <p>{intl.formatMessage(messages.casesDescription)}</p>
        <Form method='post'>
          <Input
            action={<Button primary onClick={getMyCase}>{intl.formatMessage(messages.search)}</Button>}
            type='text'
            name='emplid'
            size='30'
            value={emplid}
            placeholder={intl.formatMessage(messages.cunyID)}
            pattern='[0-9]{8}'
            onChange={handleChange}
          />

          {loading && <div>{intl.formatMessage(messages.loading)}</div>}
          {error && <div>{error}</div>}

          {items && items.length > 0 ? (
            <Table className='table'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{intl.formatMessage(messages.caseID)}</Table.HeaderCell>
                  <Table.HeaderCell>{intl.formatMessage(messages.descriptionLabel)}</Table.HeaderCell>
                  <Table.HeaderCell>{intl.formatMessage(messages.created)}</Table.HeaderCell>
                  <Table.HeaderCell>{intl.formatMessage(messages.statusName)}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.map((item) => (
                  <Table.Row key={item.case_id}>
                    <Table.Cell>
                      <a
                        className='btn btn-outline-primary'
                        title={`View details for Case #: ${item?.case_id}`}
                        href={`https://ithelp.york.cuny.edu/userui/ticket?ID=${item?.case_id}`}
                      >
                        {item?.case_id}
                      </a>
                    </Table.Cell>
                    <Table.Cell>{item?.description}</Table.Cell>
                    <Table.Cell>{item?.created}</Table.Cell>
                    <Table.Cell>{item?.status_name}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            !loading && !error && <div>{intl.formatMessage(messages.noCasesFound)}</div>
          )}
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  queue_name: state.case_reducer.queue_name,
  case_id: state.case_reducer.case_id,
  user_empid: state.case_reducer.user_empid,
  created: state.case_reducer.created,
  service: state.case_reducer.service,
  last_comment: state.case_reducer.last_comment,
  status_name: state.case_reducer.status_name,
  description: state.case_reducer.description,
  department: state.case_reducer.department,
  submitter_name: state.case_reducer.submitter_name,
  first_name: state.case_reducer.first_name,
  last_name: state.case_reducer.last_name,
  work_phone: state.case_reducer.work_phone,
  email_address: state.case_reducer.email_address,
});

const mapDispatchToProps = {
  my_case,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCaseBlock);
