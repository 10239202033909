import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { getITProjects } from '../../actions/it_projects/it_projects';
import { Table } from 'semantic-ui-react'; // Import Table from Semantic UI React

const messages = defineMessages({
  loading: {
    id: 'ycitprojects.loading',
    defaultMessage: 'Loading IT Projects...',
  },
  noProjects: {
    id: 'ycitprojects.noprojects',
    defaultMessage: 'No IT Projects found.',
  },
  error: {
    id: 'ycitprojects.error',
    defaultMessage: 'Error loading IT Projects.',
  },
  projectName: {
    id: 'ycitprojects.projectName',
    defaultMessage: 'Project Name',
  },
  projectId: {
    id: 'ycitprojects.projectId',
    defaultMessage: 'Project ID',
  },
  initiatingDepartment: {
    id: 'ycitprojects.initiatingDepartment',
    defaultMessage: 'Initiating Department',
  },
  projectState: {
    id: 'ycitprojects.projectState',
    defaultMessage: 'Project State',
  },
  projectEstStart: {
    id: 'ycitprojects.projectEstStart',
    defaultMessage: 'Project Est. Start',
  },
  projectEstEnd: {
    id: 'ycitprojects.projectEstEnd',
    defaultMessage: 'Project Est. End',
  },
  summary: {
    id: 'ycitprojects.summary',
    defaultMessage: 'Summary',
  },
});

const YCITProjectsEdit = ({ itProjects, getITProjects, loading, error }) => {
  const intl = useIntl();

  useEffect(() => {
    getITProjects();
  }, [getITProjects]);

  if (loading) {
    return <div>{intl.formatMessage(messages.loading)}</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!itProjects || itProjects.length === 0) {
    return <div>{intl.formatMessage(messages.noProjects)}</div>;
  }

  return (
    <div className='yc-it-projects-edit'>
      <h2>IT Projects</h2>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {intl.formatMessage(messages.projectName)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {intl.formatMessage(messages.projectId)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {intl.formatMessage(messages.initiatingDepartment)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {intl.formatMessage(messages.projectState)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {intl.formatMessage(messages.projectEstStart)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {intl.formatMessage(messages.projectEstEnd)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {intl.formatMessage(messages.summary)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {itProjects.map((project) => (
            <Table.Row key={project.Project_ID}>
              <Table.Cell>{project.Project_Name}</Table.Cell>
              <Table.Cell>{project.Project_ID}</Table.Cell>
              <Table.Cell>{project.Initiating_Department}</Table.Cell>
              <Table.Cell>{project.Project_State}</Table.Cell>
              <Table.Cell>{project.Project_Est_Start}</Table.Cell>
              <Table.Cell>{project.Project_Est_End}</Table.Cell>
              <Table.Cell>{project.Summary}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  itProjects: state.itProjects.items,
  loading: state.itProjects.loading,
  error: state.itProjects.error,
});

const mapDispatchToProps = {
  getITProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(YCITProjectsEdit);
