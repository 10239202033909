import { MY_CASE } from 'volto-yc-myapi/constants/ActionTypes';
import { flattenToAppURL } from '@plone/volto/helpers';

export function my_case(emplid) {
  return {
    type: MY_CASE,
    request: {
      op: 'get',
      path: flattenToAppURL(`/@my_case?emplid=${emplid}`),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: { emplid },
    },
  };
}
