/**
 * Navigation components.
 * @module components/theme/Navigation/Navigation
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions';
import { CSSTransition } from 'react-transition-group';
import { Link, NavLink, withRouter } from 'react-router-dom'; // Import withRouter
import { Logo } from '@plone/volto/components';
import { links } from './megamenu';
import { Icon } from 'semantic-ui-react';

class Navigation extends Component {
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    lang: PropTypes.string.isRequired,
    history: PropTypes.shape({ // Add history prop
      listen: PropTypes.func.isRequired,
      location: PropTypes.shape({ // Add location prop
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    token: null,
  };

  state = {
    mobileMenu: false,
    primaryNav: '',
    secondaryNav: '',
    tertiaryNav: '',
    sectionNav: '',
    scrolled: false,
    isLoading: false, // Add loading state
  };

  componentDidMount() {
    const { settings } = config;
    const { pathname } = this.props.location; // Get initial pathname

    if (!hasApiExpander('navigation', getBaseUrl(pathname))) {
      this.props.getNavigation(
        getBaseUrl(pathname),
        settings.navDepth,
      );
    }
    window.addEventListener('scroll', this.handleScroll); // Add scroll event listener

    // Add loading class on route change
    this.props.history.listen((location, action) => {
      if (location.pathname !== this.props.location.pathname) {
        this.setState({ isLoading: true }); // Set loading state to true
      } else {
        // Check if navigating to same route, such as clicking the logo on homepage
        if (location.pathname === this.props.location.pathname && action === 'PUSH') {
          this.setState({ isLoading: false });
        }
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll); // Remove scroll event listener
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settings } = config;
    if (
      nextProps.pathname !== this.props.pathname ||
      nextProps.token !== this.props.token
    ) {
      if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
        this.props.getNavigation(
          getBaseUrl(nextProps.pathname),
          settings.navDepth,
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the items prop has changed or if the loading was initiated
    if (this.props.items !== prevProps.items && this.state.isLoading) {
      this.setState({ isLoading: false }); // Clear loading state if props change
    }
  }

  handleScroll = () => {
    const isScrolled = window.scrollY > 50; // Adjust the scroll threshold as needed
    if (isScrolled !== this.state.scrolled) {
      this.setState({ scrolled: isScrolled });
    }
  };
  closeMobileMenu = () => {
    this.setState({
      primaryNav: '',
      secondaryNav: '',
      tertiaryNav: '',
      sectionNav: '',
      mobileMenu: false,
    });
  };

  render() {
    const { items } = this.props; // Get items from props

    return (
      <>
        <div
          className={`${
            this.state.mobileMenu === true
              ? 'cd-main-header nav-is-visible'
              : 'cd-main-header'
          } ${this.state.scrolled ? 'scrolled' : ''} ${
            this.state.isLoading ? 'cd-main-header--loading' : ''
          }`}
        >
          <Link className='cd-logo' to='/' onClick={this.closeMobileMenu}>
            <Logo />
          </Link>
          <Link
            to='/admissions'
            className='ui mini button primary'
            id='cd-mobile-apply'
            onClick={this.closeMobileMenu}
          >
            Apply
          </Link>
          <ul className='cd-header-buttons'>
            <li>
              <a
                aria-label='Search'
                className={`${
                  this.state.primaryNav === 'search'
                    ? 'cd-search-trigger search-is-visible'
                    : 'cd-search-trigger'
                }`}
                onClick={() => {
                  this.setState((prevState) => ({
                    primaryNav: prevState.primaryNav !== 'search' ? 'search' : '',
                    secondaryNav: '',
                    sectionNav: '',
                    mobileMenu: false,
                  }));
                }}
                href='javascript:'
              >
                <span></span>
                <span className='is-hidden'>Search</span>
              </a>
            </li>

            <li>
              <a
                className={`${
                  this.state.mobileMenu === true
                    ? 'cd-nav-trigger nav-is-visible'
                    : 'cd-nav-trigger'
                }`}
                onClick={() => {
                  this.setState((prevState) => ({
                    mobileMenu: !prevState.mobileMenu,
                    primaryNav: '',
                    secondaryNav: '',
                    sectionNav: '',
                  }));
                }}
                href='javascript:'
              >
                <span>Menu</span>
              </a>
            </li>
          </ul>
          <nav
            className={`${
              this.state.mobileMenu === true ? 'cd-nav cd-nav-is-visible' : 'cd-nav'
            }`}
            id='navigation'
            aria-label='navigation'
          >
            <ul
              id='cd-primary-nav'
              className={`${
                this.state.mobileMenu === true
                  ? 'cd-primary-nav nav-is-visible'
                  : 'cd-primary-nav'
              } ${this.state.primaryNav !== '' ? 'moves-out' : ''}`}
            >
              {links.map((link) => (
                <li className='has-children'>
                  <a
                    className={`${this.state.primaryNav === link.name ? 'selected' : ''}`}
                    onClick={(event) => {
                      event.preventDefault();
                      this.state.primaryNav !== link.name
                        ? this.setState((prevState) => ({ primaryNav: link.name }))
                        : this.setState((prevState) => ({ primaryNav: '' }));
                      this.setState((prevState) => ({ secondaryNav: '' }));
                      this.setState((prevState) => ({ tertiaryNav: '' }));
                      this.setState((prevState) => ({ sectionNav: '' }));
                    }}
                    href={link.link}
                  >
                    {link.name}
                  </a>

                  <CSSTransition
                    in={this.state.primaryNav === link.name}
                    timeout={500}
                    classNames='primary'
                    unmountOnExit
                  >
                    <ul
                      className={
                        link.styles +
                        ` ${this.state.primaryNav !== link.name ? 'cd-hidden' : ''}` +
                        ` ${this.state.secondaryNav !== '' ? 'moves-out' : ''}`
                      }
                    >
                      <li className='go-back'>
                        <a
                          href='#'
                          onClick={(event) => {
                            event.preventDefault();
                            this.state.primaryNav !== link.name
                              ? this.setState((prevState) => ({ primaryNav: link.name }))
                              : this.setState((prevState) => ({ primaryNav: '' }));
                            this.setState((prevState) => ({ secondaryNav: '' }));
                            this.setState((prevState) => ({ sectionNav: '' }));
                          }}
                        >
                          Main Menu
                        </a>
                      </li>
                      <li className='see-all'>
                        {link.link.startsWith('http') ? (
                          <a href={link.link} onClick={this.closeMobileMenu}>
                            {link.description}
                          </a>
                        ) : (
                          <Link to={link.link} onClick={this.closeMobileMenu}>
                            {link.description}
                          </Link>
                        )}
                      </li>
                      {link.styles === 'cd-nav-icons' &&
                        link.sublinks.map((secondary) => (
                          <li>
                            {secondary.link.startsWith('http') ? (
                              <a
                                className={'cd-nav-item ' + secondary.styles}
                                href={secondary.link}
                                onClick={this.closeMobileMenu}
                              >
                                <h3>{secondary.name}</h3>
                                <p>{secondary.description}</p>
                              </a>
                            ) : (
                              <Link
                                className={'cd-nav-item ' + secondary.styles}
                                to={secondary.link}
                                onClick={this.closeMobileMenu}
                              >
                                <h3>{secondary.name}</h3>
                                <p>{secondary.description}</p>
                              </Link>
                            )}
                          </li>
                        ))}

                      {link.styles === 'cd-nav-gallery' &&
                        link.sublinks.map((secondary) => (
                          <li>
                            {secondary.link.startsWith('http') ? (
                              <a
                                className={'cd-nav-item ' + secondary.styles}
                                href={secondary.link}
                                onClick={this.closeMobileMenu}
                              >
                                <img
                                  src={secondary.image}
                                  className='img-circle'
                                  alt=' '
                                />
                                <h3>{secondary.name}</h3>
                              </a>
                            ) : (
                              <Link
                                className={'cd-nav-item ' + secondary.styles}
                                to={secondary.link}
                                onClick={this.closeMobileMenu}
                              >
                                <img
                                  src={secondary.image}
                                  className='img-circle'
                                  alt=' '
                                />
                                <h3>{secondary.name}</h3>
                              </Link>
                            )}
                          </li>
                        ))}

                      {link.styles === 'cd-secondary-nav' &&
                        link.sublinks.map((secondary) => (
                          <li
                            key={secondary.name}
                            className={
                              secondary.sublink && secondary.sublink.length > 0
                                ? 'has-children'
                                : secondary.styles
                            }
                          >
                            {this.state.mobileMenu ? (
                              <a
                                href={secondary.link}
                                className={
                                  secondary.sublink && secondary.sublink.length > 0
                                    ? ''
                                    : secondary.styles
                                }
                                onClick={(event) => {
                                  event.preventDefault();
                                  if (secondary.sublink && secondary.sublink.length > 0) {
                                    this.setState((prevState) => ({
                                      secondaryNav:
                                        prevState.secondaryNav !== secondary.name
                                          ? secondary.name
                                          : '',
                                      tertiaryNav: '',
                                    }));
                                  } else if (secondary.link) {
                                    this.closeMobileMenu();
                                    window.location.href = secondary.link;
                                  }
                                }}
                              >
                                {secondary.name}
                              </a>
                            ) : (
                              secondary.link ? (
                                secondary.link.startsWith('http') ? (
                                  <a
                                    href={secondary.link}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    onClick={this.closeMobileMenu}
                                  >
                                    {secondary.name}
                                  </a>
                                ) : (
                                  <Link to={secondary.link} onClick={this.closeMobileMenu}>
                                    {secondary.name}
                                  </Link>
                                )
                              ) : null // Render nothing if secondary.link is undefined
                            )}

                            {/* Secondary Nav (only if sublinks exist) */}
                            {secondary.sublink && secondary.sublink.length > 0 && (
                              <ul
                                className={`${
                                  this.state.secondaryNav !== secondary.name
                                    ? 'cd-hidden'
                                    : ''
                                } ${
                                  this.state.tertiaryNav !== '' &&
                                  this.state.secondaryNav === secondary.name
                                    ? 'moves-out'
                                    : ''
                                }`}
                              >
                                {/* Mobile-only Back and See All */}
                                {this.state.mobileMenu && (
                                  <>
                                    <li className='go-back'>
                                      <a
                                        href='#'
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.setState({ secondaryNav: '', tertiaryNav: '' });
                                        }}
                                      >
                                        Back to {link.name}
                                      </a>
                                    </li>
                                    <li className='see-all'>
                                      <a href={secondary.link} onClick={this.closeMobileMenu}>
                                        {secondary.name}
                                      </a>
                                    </li>
                                  </>
                                )}

                                {/* Tertiary Navigation */}
                                {secondary.sublink?.map((tertiaryNav) => (
                                  <li key={tertiaryNav.name}>
                                    {/* Direct link handling for has-children */}
                                    {tertiaryNav.styles === 'has-children' ? (
                                      <Link
                                        to={tertiaryNav.link}
                                        onClick={this.closeMobileMenu}
                                      >
                                        {tertiaryNav.name}
                                      </Link>
                                    ) : (
                                      <>
                                        {tertiaryNav.link.startsWith('http') ? (
                                          <a
                                            className={tertiaryNav.styles}
                                            href={tertiaryNav.link}
                                            onClick={this.closeMobileMenu}
                                          >
                                            {tertiaryNav.name}
                                          </a>
                                        ) : (
                                          <Link
                                            className={tertiaryNav.styles}
                                            to={tertiaryNav.link}
                                            onClick={this.closeMobileMenu}
                                          >
                                            {tertiaryNav.name}
                                          </Link>
                                        )}
                                      </>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                    </ul>
                  </CSSTransition>
                </li>
              ))}

              <li className='cd-section'>
                {items[0]?.url === '' ? null : (
                  <ul
                    className={
                      this.state.sectionNav !== '' ? 'section-menu moves-out' : 'section-menu'
                    }
                  >
                    {items.map((item) => (
                      <>
                        {item.items && item.items.length ? (
                          <li className='has-children'>
                            {this.state.mobileMenu === false && (
                              item.url.startsWith('http') ? (
                                <a href={item.url} key={item.url}>
                                  {item.title}
                                </a>
                              ) : (
                                <NavLink to={item.url === '' ? '/' : item.url} key={item.url}>
                                  {item.title}
                                </NavLink>
                              )
                            )}
                            {this.state.mobileMenu === true && (
                              <NavLink
                                to={item.url === '' ? '/' : item.url}
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.state.sectionNav !== item.title
                                    ? this.setState((prevState) => ({
                                      sectionNav: item.title,
                                    }))
                                    : this.setState((prevState) => ({ sectionNav: '' }));
                                  this.setState((prevState) => ({ secondaryNav: '' }));
                                }}
                              >
                                {item.title}
                              </NavLink>
                            )}
                            <ul
                              className={
                                this.state.sectionNav !== item.title
                                  ? 'cd-hidden'
                                  : 'is-visible'
                              }
                            >
                              {this.state.mobileMenu === true && (
                                <>
                                  <li className='go-back'>
                                    <a
                                      href='#'
                                      onClick={(event) => {
                                        event.preventDefault();
                                        this.state.sectionNav !== item.title
                                          ? this.setState((prevState) => ({
                                            sectionNav: item.title,
                                          }))
                                          : this.setState((prevState) => ({ sectionNav: '' }));
                                        this.setState((prevState) => ({ secondaryNav: '' }));
                                      }}
                                    >
                                      Section Menu
                                    </a>
                                  </li>
                                  <li className='see-all'>
                                    <NavLink
                                      activeClassName='active'
                                      to={item.url === '' ? '/' : item.url}
                                      onClick={this.closeMobileMenu}
                                    >
                                      {item.title}{' '}
                                    </NavLink>
                                  </li>
                                </>
                              )}
                              {item.items.map((subitem) => (
                                <li key={subitem.url}>
                                  {subitem.url.startsWith('http') ? (
                                    <a
                                      activeClassName='active'
                                      href={subitem.url}
                                      onClick={this.closeMobileMenu}
                                    >
                                      {subitem.title}
                                    </a>
                                  ) : (
                                    <NavLink
                                      activeClassName='active'
                                      to={subitem.url}
                                      key={subitem.url}
                                      onClick={this.closeMobileMenu}
                                    >
                                      {subitem.title}
                                    </NavLink>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ) : (
                          <li>
                            {item.url.startsWith('http') ? (
                              <a
                                activeClassName='active'
                                href={item.url}
                                onClick={this.closeMobileMenu}
                              >
                                {item.title}{' '}
                              </a>
                            ) : (
                              <NavLink
                                activeClassName='active'
                                to={item.url}
                                key={item.url}
                                onClick={this.closeMobileMenu}
                              >
                                {item.title.toLowerCase() === 'home' ? (
                                  this.props.rootTitle ? (
                                    <span className='section-class'>
                                      {this.props.rootTitle}
                                    </span>
                                  ) : (
                                    item.title
                                  )
                                ) : (
                                  item.title
                                )}
                              </NavLink>
                            )}
                          </li>
                        )}
                      </>
                    ))}
                  </ul>
                )}
              </li>
              <li className='cd-prenav'>
                <Link
                  to='/calendar'
                  className='ui mini button secondary'
                  onClick={this.closeMobileMenu}
                >
                  <Icon name='calendar' size='large' />
                  Calendar{' '}
                </Link>
                <Link
                  to='/directory'
                  className='ui mini button secondary'
                  onClick={this.closeMobileMenu}
                >
                  <Icon name='user' size='large' />
                  Directory{' '}
                </Link>
                <Link
                  to='/admissions'
                  className='ui mini button primary'
                  onClick={this.closeMobileMenu}
                >
                  Apply
                </Link>
                <Link
                  to='/give'
                  className='ui mini button primary'
                  onClick={this.closeMobileMenu}
                >
                  Give{' '}
                </Link>
                <Link
                  to='/rent-a-space'
                  className='ui mini button primary'
                  onClick={this.closeMobileMenu}
                >
                  Rent{' '}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <CSSTransition
          in={this.state.mobileMenu === true | (this.state.primaryNav !== '')}
          timeout={500}
          classNames='in'
          unmountOnExit
        >
          <div className='cd-overlay is-visible' onClick={this.closeMobileMenu} />
        </CSSTransition>

        <CSSTransition
          in={this.state.primaryNav === 'search'}
          timeout={500}
          classNames='cd-search'
          unmountOnExit
        >
          <div id='cd-search' className='cd-search is-visible' role='search'>
            <form action='https://www.google.com/cse' id='cse-search-box'>
              <input name='cx' type='hidden' value='014698858644969705766:fdu3aeb0rno' />
              <input name='ie' type='hidden' value='UTF-8' />
              <input
                name='q'
                size='30'
                type='search'
                placeholder='Search...'
                aria-label='Search text'
                ref={(input) => {
                  this.searchInput = input;
                }}
              />
              <input
                name='sa'
                type='submit'
                value='Search'
                className='gs'
                aria-label='Search'
              />
            </form>
          </div>
        </CSSTransition>
      </>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      token: state.userSession.token,
      items: state.navigation.items,
      lang: state.intl.locale,
      rootTitle: state.navroot?.data?.navroot?.title,
    }),
    { getNavigation },
  ),
  withRouter, // Wrap with withRouter
)(Navigation);
